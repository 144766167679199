@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-contact-panel {
    .form-container {
        background-color: #49BDD4;
        max-width: 1160px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        .pb-separator {
            left: 0;
            top: 0;
            position: absolute;
            z-index: 10;
            img {
                max-width: 80%;
                margin: 0 auto;
                display: block;
                transform: translateY(-50%);
            }
        }
        .leftcol {
            display: flex;
            flex-basis: 545px;
            align-items: center;
            justify-content: center;
            position: relative;
            .form-logo {
                width: 250px;
                height: auto;
                top: 1.65em;
                left: 1.25em;
                position: absolute;
            }
            .panel-title {
                color: $white;
                margin-top: 32px;
                padding: 1em;
                font-family: $font-med;
                font-size: 43.5px;
                text-shadow: 0 0 12px rgba($black, 0.6);
                line-height: 1.1em;
                position: relative;
            }
            &::before {
                content: "";
                background-image: var(--form-image);
                background-repeat: no-repeat;
                background-size: cover;
                background-color: $black;
                mix-blend-mode: multiply;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        }
        .rightcol {
            padding: 4em 3.75em 3em;
            flex-basis: 615px;
            h3 {
                margin-bottom: 2em;
                font-family: $font-med;
                font-size: 30px;
            }
        }
    }
    @include bpr-media-breakpoint(down, xl) {
        .form-container {
            .rightcol {
                padding: 2.75em 2.25em 2.5em;
            }
        }
	}
    @include bpr-media-breakpoint(down, lg) {
        .form-container {
            .rightcol {
                h3 {
                    text-align: center;
                }
            }
        }
	}
    @include bpr-media-breakpoint(down, md) {
        .form-container {
            .leftcol {
                display: none;
            }
            .rightcol {
                flex-basis: 100%;
                h3 {
                    line-height: 1.25em;
                }
            }
        }
	}
}